import { Link } from 'react-router-dom'
export default function ProductDetail({ product, history }) {
    return (
        product != null ?
            <section id="detail">
                <div className="product-detail">
                    <div className="photo-card">
                        <img src={"https://panel.fiyat.az/image/" + product.productPhotoUrl}
                            alt={product.productName} draggable="false" />
                    </div>
                    <div className="text-card">
                        <div className='category-road'>
                            {
                                history.map(cat => {
                                    return (
                                        <Link to={'/Filter/' + cat.categoryId} key={cat.categoryId}><span>{cat.categoryName}<i className="fa-solid fa-chevron-right"></i></span></Link>
                                    )
                                })
                            }
                        </div>
                        <div className="title">
                            <h1>{product.productName} <small>{product.productPrice} Azn</small></h1>
                            <a href={product.productRedirectUrl} target='_blank'>
                                <h3> {product.productStoreName}</h3> <small> Magazaya get </small>
                            </a>
                        </div>
                        <div className="textarea">
                            <p>{product.productText}</p>
                        </div>
                    </div>
                </div>
            </section>
            : null
    )
}
