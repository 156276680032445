import React from 'react'
import { Link } from 'react-router-dom'

export default function PopularProduct({ product }) {
  return (

    <div className="product">
      <img src={'https://panel.fiyat.az/image/' + product.productPhotoUrl} alt={product.productName} />
      <div className="text">
        <h3>{product.productName}</h3>
        <p><span>qiymət:</span><span>{product.productPrice} Azn</span></p>
        <p><span>mağaza:</span><span>{product.productStoreName}</span></p>
      </div>
      <Link to={"/Detail/" + product.productId}>Ətraflı</Link>
    </div>

  )
}
