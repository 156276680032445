import React from 'react'
import Header from '../Components/Header'
import Categories from '../Components/Categories'
import PopularProducts from '../Components/PopularProducts'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from "axios"
import Loader from '../Components/Loader'
export default function Home() {
  const [slides, setSlides] = useState([])
  const [category, setCategory] = useState([])
  const [products, setProducts] = useState([])
  useEffect(() => {
    axios.get('https://panel.fiyat.az/api/Home').then(res => {
      setSlides(res.data.slider)
      setCategory(res.data.category)
      setProducts(res.data.products)
    })
  }, [])
  return (
    <>
      <Header slides={slides}></Header>
      <Categories category={category}></Categories>
      <PopularProducts products={products}></PopularProducts>
      {slides.length === 0 ? <Loader /> : null}
    </>
  )
}
