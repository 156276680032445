import ProductDetail from '../Components/ProductDetail'
import SameProducts from '../Components/SameProducts'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Loader from '../Components/Loader'
export default function Detail() {
  const { id } = useParams()
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState(null)
  const [history, setHistory] = useState([])
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setLoad(true)
    axios.get('https://panel.fiyat.az/api/Home/detail/' + id).then(res => {
      setProducts(res.data.products)
      setProduct(res.data.product)
      setHistory(res.data.history)
      setLoad(false)
      window.scrollTo({ top: 0 })
    })
  }, [id])
  return (
    <>
      {load ? <Loader /> : null}
      <ProductDetail product={product} history={history}></ProductDetail>
      <SameProducts products={products}></SameProducts>
    </>
  )
}
