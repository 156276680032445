import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Views/Layout';
import Home from './Views/Home'
import Detail from './Views/Detail'
import Filter from "./Views/Filter"
import Rules from './Views/Rules';
import About from './Views/About';
export default function App() {
  return (
    <BrowserRouter> 
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='detail/:id' element={<Detail/>}/>
          <Route path='filter/:id' element={<Filter/>}></Route>
          <Route path='rules' element={<Rules/>}></Route>
          <Route path='about' element={<About/>}></Route>

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

