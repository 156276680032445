import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom';
export default function Categories({ category }) {
    return (
        <section id="categories">
            <h2>Kateqoriyalar</h2>
            <div className="items">
                {
                    category.map(cat => {
                        return (
                            <Link to={"/Filter/" + cat.categoryId} key={cat.categoryId}>
                                <div className="item">
                                    <i className="fa-solid fa-hashtag"></i>
                                    <h3>{cat.categoryName}</h3>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </section>
    )
}
