import React from 'react'
import '../style/style.css'
import { Outlet } from 'react-router-dom'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
export default function Layout() {
  return (
    <>
    <Nav></Nav>
    <Outlet />
    <Footer></Footer>
  </>
  )
}
