import React from 'react'
import { Link } from 'react-router-dom'

export default function FilterCategory({ cat }) {

  return (
    <Link to={'/Filter/' + cat.categoryId}><div className="item" key={cat.categoryId}>
      <i className="fa-solid fa-hashtag"></i>
      <h3>{cat.categoryName}</h3>
    </div></Link>
  )
}
