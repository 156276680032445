import React, { useEffect, useState } from 'react'
import FilterCategories from '../Components/FilterCategories'
import FilterProducts from '../Components/FilterProducts'
import { useParams } from 'react-router-dom'
import axios from "axios";
import Loader from '../Components/Loader';

export default function Filter() {
  const { id } = useParams()
  const [category, setCategory] = useState([])
  const [historyCategory, setHistoryCategory] = useState([])
  const [products, setProducts] = useState([])
  const [load, setLoad] = useState(true)
  useEffect(() => {
    setLoad(true)
    axios.get('https://panel.fiyat.az/api/Home/filter/' + id).then(res => {
      setCategory(res.data.subCategories)
      setProducts(res.data.products)
      setHistoryCategory(res.data.history)
      setLoad(false)
    })
  }, [id])
  return (
    <>
      {load ? <Loader /> : null}
      <FilterCategories category={category} historyCategory={historyCategory}></FilterCategories>
      <FilterProducts products={products} id={id}></FilterProducts>
    </>
  )
}
