import React from 'react'
import { Link } from 'react-router-dom'
import FilterCategory from './FilterCategory'
export default function FilterCategories({ category, historyCategory }) {
    return (
        <section id="categories">
            <h2>Kateqoriyalar</h2>
            <div className='category-road'>
                {
                    historyCategory.map(cat => {
                        return (
                            <Link to={'/Filter/' + cat.categoryId} key={cat.categoryId}><span>{cat.categoryName}<i className="fa-solid fa-chevron-right"></i></span></Link>
                        )
                    })
                }
            </div>
            <div className="items">
                {
                    category.map(cat => {

                        return (
                            <FilterCategory cat={cat} key={cat.categoryId}></FilterCategory>
                        )
                    })
                }
            </div>
        </section>
    )
}
