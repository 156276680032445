import FilterProduct from './FilterProduct'
export default function SameProducts({ products }) {
  return (
    <section id="popular">
      <h2>Bənzər məhsullar</h2>
      <div className="products">
        {
          products.map(product => {
            return <FilterProduct product={product} key={product.productId}></FilterProduct>
          })
        }
      </div>
    </section>
  )
}
