import { useEffect } from 'react'
import { useState } from 'react'
import axios from "axios"
import { Link } from 'react-router-dom'
export default function Nav() {
    const [activeClass, setActiveClass] = useState(false)
    const [search, setSearch] = useState(null)
    const [searchRes, setSearchRes] = useState([])
    const [category, setCategory] = useState([])
    const [modalSearch, setModalSearch] = useState(false)
    useEffect(() => {
        axios.get('https://panel.fiyat.az/api/Home/categories').then(res => {
            setCategory(res.data)
        })
    }, [])
    useEffect(() => {
        if (search != null && search.length >= 3) {
            axios.get('https://panel.fiyat.az/api/Home/search/' + search).then(res => {
                setSearchRes(res.data)
            })
        } else {
            setSearchRes([])
        }
    }, [search])
    const handleClick = () => {
        setActiveClass(!activeClass)
    }
    return (
        <>

            {
                modalSearch ?
                    <div className='search-modal'>

                        <div className='modal'>
                            <i className="fa-solid fa-xmark" onClick={() => { setModalSearch(false); setSearchRes([]) }}></i>
                            <h3>Axtarış etmək istədiyiniz mehsulu daxil edin.</h3>
                            <div className='modal-result'>
                                <div className='inp-search'>
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                    <input type='text' placeholder='Mehsul daxil edin' onKeyUp={(event) => setSearch(event.target.value)} defaultValue={search} id='modalAxtar'></input>
                                </div>
                                <ul>
                                    {
                                        searchRes.map(res => {
                                            return (
                                                <li key={res.productId}>
                                                    <Link to={`/Detail/${res.productId}`} onClick={() => { document.querySelector('#modalAxtar').value = res.productName; setSearchRes([]);; setModalSearch(false) }}><span>{res.productName}</span> - {res.productPrice} Azn</Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    : null
            }
            <nav>
                <Link to={'/'}><h1>FİYAT</h1></Link>
                <div className="search">
                    <div className="categories">
                        <span onClick={() => handleClick()}>
                            Kateqoriyalar
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 15" role="img" aria-label="Arrow down">
                                <path
                                    d="M12 15c-.3 0-.6-.1-.8-.4l-11-13C-.2 1.2-.1.5.3.2c.4-.4 1.1-.3 1.4.1L12 12.5 22.2.4c.4-.4 1-.5 1.4-.1.4.4.5 1 .1 1.4l-11 13c-.1.2-.4.3-.7.3z">
                                </path>
                            </svg>
                        </span>
                        <ul className={activeClass ? 'active' : ''}>
                            {
                                category.map(cat => {
                                    return (
                                        <li key={cat.categoryId} onClick={() => handleClick()}>
                                            <i className="fa-solid fa-hashtag"></i>
                                            <span><Link to={`/Filter/${cat.categoryId}`}>{cat.categoryName}</Link></span>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                    </div>
                    <div className='nav-search'>
                        <input type="search" placeholder="açar söz..." onKeyUp={(event) => setSearch(event.target.value)} defaultValue={search} id='axtar' />
                        <div className='search-result'>
                            <ul>
                                {
                                    searchRes.map(res => {
                                        return (
                                            <li key={res.productId}>
                                                <Link to={`/Detail/${res.productId}`} onClick={() => { setSearchRes([]); document.querySelector('#axtar').value = res.productName }}><span>{res.productName}</span> - {res.productPrice} Azn</Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <svg className="icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="32px"
                        height="32px">
                        <path
                            d="M 56.599609 21.599609 C 34.099609 21.599609 15.800781 40.100781 15.800781 62.800781 C 15.800781 85.600781 34.099609 104 56.599609 104 C 66.899609 104 76.3 100.09922 83.5 93.699219 L 85.800781 96 L 83.699219 98.199219 C 82.499219 99.399219 82.499219 101.3 83.699219 102.5 L 101.69922 120.69922 C 102.29922 121.29922 103.00078 121.59961 103.80078 121.59961 C 104.60078 121.59961 105.40039 121.29922 105.90039 120.69922 L 113.90039 112.59961 C 115.00039 111.39961 115.00078 109.50039 113.80078 108.40039 L 95.800781 90.199219 C 95.200781 89.599219 94.499219 89.300781 93.699219 89.300781 C 92.899219 89.300781 92.099609 89.599219 91.599609 90.199219 L 89.5 92.400391 L 87.199219 90 C 93.499219 82.7 97.400391 73.200781 97.400391 62.800781 C 97.400391 40.100781 79.099609 21.599609 56.599609 21.599609 z M 56.599609 27.699219 C 75.799609 27.699219 91.400391 43.500391 91.400391 62.900391 C 91.400391 82.300391 75.799609 98 56.599609 98 C 37.399609 98 21.800781 82.300391 21.800781 62.900391 C 21.800781 43.500391 37.399609 27.699219 56.599609 27.699219 z M 56.699219 40.199219 C 47.199219 40.199219 38.7 46.300781 35.5 55.300781 C 35 56.600781 35.699609 58.199609 37.099609 58.599609 C 37.399609 58.699609 37.7 58.800781 38 58.800781 C 39.1 58.800781 40.1 58.1 40.5 57 C 42.9 50.1 49.499219 45.400391 56.699219 45.400391 C 58.099219 45.400391 59.300781 44.200781 59.300781 42.800781 C 59.300781 41.400781 58.099219 40.199219 56.699219 40.199219 z M 37.699219 64.900391 C 36.299219 64.900391 35.099609 66 35.099609 67.5 L 35.099609 67.900391 C 35.199609 69.300391 36.300781 70.5 37.800781 70.5 C 39.200781 70.5 40.400391 69.300391 40.400391 67.900391 L 40.400391 67.599609 C 40.400391 66.099609 39.300781 64.900391 37.800781 64.900391 L 37.699219 64.900391 z M 93.800781 96.599609 L 107.59961 110.59961 L 103.80078 114.40039 L 90 100.40039 L 93.800781 96.599609 z" />
                    </svg>
                    <i className="fa-solid fa-magnifying-glass search-icon" onClick={() => setModalSearch(true)}></i>
                </div>
                <ul className="menu">
                    <li><Link to={'/Rules'}>Şərtlər</Link></li>
                    <li><Link to={'/About'}>Biz kimik?</Link></li>
                </ul>
            </nav>
        </>
    )
}
