import React, { useEffect, useState } from 'react'
import axios from "axios"
export default function Rules() {
    const [rules,setRules]=useState([]);
    useEffect(() => {
        axios.get("https://panel.fiyat.az/api/Home/about").then(res => {
            setRules(res.data)
        })

    }, [])
    return (
        <section id='rules'>
            <div className='rule-title'>
            <h1>Şərtlərimiz</h1>
            </div>
            <div className='chk' dangerouslySetInnerHTML={{ __html : rules.aboutRules }}></div>
        </section>
    )
}
