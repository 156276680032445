import { useState } from 'react'
import FilterProduct from './FilterProduct'
export default function FilterProducts({ products }) {
    const [page, setPage] = useState(0)
    return (
        <section id="popular">
            <h2>Eyni Category Mehsullari</h2>
            <div className="products">
                {
                    products.slice(page * 10, page * 10 + 10).map(product => {
                        return (<FilterProduct product={product} key={product.productId}></FilterProduct>)
                    })
                }
            </div>
            <ul>
                {
                    [...Array(Math.ceil(products.length / 10))].map((x, i) => {
                        return <li onClick={() => setPage(i)} className={page === i ? "active" : ""} key={i}>{i + 1}</li>
                    })
                }
            </ul>

        </section>
    )
}
