import React from 'react'

function Loader() {
    return (
        <div className='preloader'>
            <div className='loader'></div>
        </div>
    )
}

export default Loader;