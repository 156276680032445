import React, { useEffect, useState } from 'react'
import axios from "axios"
export default function About() {
    const [about, setAbout] = useState([])
    useEffect(() => {
        axios.get("https://panel.fiyat.az/api/Home/about").then(res => {
            setAbout(res.data)
        })
    }, [])

    return (
        <section id='about'>
            <div className='about-title'>
                <h1>Biz Kimik?</h1>
            </div> 
            <div className="chk" dangerouslySetInnerHTML={{ __html : about.aboutDescritpion }}></div>
           
        </section>
    )
}
