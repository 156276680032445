import PopularProduct from './PopularProduct'
export default function PopularProducts({products}) {
    return (
        <section id="popular">
            <h2>Populyar məhsullar</h2>
            <div className="products">
                {
                    products.map(product => {
                        return (<PopularProduct product={product} key={product.productId}></PopularProduct>)
                    })
                }
            </div>
        </section>
    )
}
