import React, { useRef } from 'react'
import { useState } from 'react';
export default function Header({slides}) {
  // const [spin,setSpin]=useState(true)
  const [index, setIndex] = useState(0)
  const titleRef = useRef()
  const textRef = useRef()
  var i = 0;
 
  function funkNext() {
    index == slides.length - 1 ? setIndex(0) : setIndex(index + 1);
    titleRef.current.style.animation = "none"
    textRef.current.style.animation = "none"
    setTimeout(() => {
      titleRef.current.style.animation = null
      textRef.current.style.animation = null
    }, 100);
  }
  function funkPrev() {
    index == 0 ? setIndex(slides.length-1) : setIndex(index - 1);
    titleRef.current.style.animation = "none"
    textRef.current.style.animation = "none"
    setTimeout(() => {
      titleRef.current.style.animation = null
      textRef.current.style.animation = null
    }, 100);

  }
  return (
    slides.length > 0 ?
      <header>
        <div className="slider">
          <div className="slide">
            <div className="text">
              <h2 ref={titleRef}>{slides[index].slideTitle}</h2>
              <p ref={textRef}>{slides[index].slideText}</p>
              <div className='slide-btn'>
                <div onClick={() => funkPrev()}><i className="fa-solid fa-arrow-left-long"></i></div>
                <div onClick={() => funkNext()}><i className="fa-solid fa-arrow-right-long"></i></div>
              </div>
            </div>
            <img src={'https://panel.fiyat.az/image/' + slides[index].slidePhotoUrl} alt="" />
          </div>
        </div>
      </header> : null
  )
}
